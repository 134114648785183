import { request } from "@/utils/request";

export const userLogin = (data) => {
    return request('/api/login', data, 'POST')
};

export const getUserInfo = () => {
    return request('/api/userInfo', '', 'GET')
};

export const adminResetPwd = (userId) => {
    return request('/api/adminResetPwd/' + userId, {}, 'POST')
}

// More API functions...
