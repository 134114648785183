import { defineStore } from 'pinia'

export const useSiteStore = defineStore('site', {
  state: () => {
    return { siteName: '管理平台'}
  },
  // 也可以这样定义状态
  // state: () => ({ count: 0 })
  actions: {
    setSiteName(siteName) {
      this.siteName = siteName
    },
  },
  persist: {
    enabled: true // true 表示开启持久化保存
  }
})
