import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";

import Home from "@/views/home-page.vue";
import Profile from "@/views/profile-page.vue";
import defaultLayout from "../layouts/side-nav-outer-toolbar.vue";
import simpleLayout from "@/layouts/single-card.vue";
import {defineAsyncComponent, resolveDynamicComponent} from "vue";
import {useUserStore} from "@/store/modules/user.js";
import {getUserInfo} from "@/api/auth.js";
let modules = import.meta.glob("@/views/**/*.vue");
function loadView(view) {
    return () => import (/* webpackChunkName: "login" */ `@/views/${view}.vue`)
}

function commonLoadView(path) {
    return () => import (/* webpackChunkName: "login" */ `@/views/${path}.vue`)
}

let router = new createRouter({
    routes: [
        {
            path: "/home",
            name: "home",
            meta: {
                requiresAuth: true,
                layout: defaultLayout
            },
            component: Home
        },
        {
            path: "/profile",
            name: "profile",
            meta: {
                requiresAuth: true,
                layout: defaultLayout
            },
            component: Profile
        },
        {
            path: "/login-form",
            name: "login-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "后台登录"
            },
            component: loadView("login-form")
        },
        {
            path: "/reset-password",
            name: "reset-password",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Reset Password",
                description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
            },
            component: loadView("reset-password-form")
        },
        {
            path: "/create-account",
            name: "create-account",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Sign Up"
            },
            component: loadView("create-account-form"),
        },
        {
            path: "/change-password/:recoveryCode",
            name: "change-password",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Change Password"
            },
            component: loadView("change-password-form")
        },
        {
            path: "/",
            redirect: "/home"
        },
        {
            path: "/recovery",
            redirect: "/home"
        }
    ],
    history: createWebHashHistory()
});

let isInitRouter = false;
router.beforeEach( async (to) => {

    // if (to.name === "login-form" && auth.loggedIn()) {
    //   next({ name: "home" });
    // }
    const userStore = useUserStore();
    if (userStore.token !== undefined && userStore.token !== "") {
        if (to.name === "login-form") {
            return "/home"
        } else {
            if (userStore.userInfo.id === undefined || userStore.userInfo.id === "") {
                const userInfo = await getUserInfo();
                if (userInfo.success) {
                    userStore.setUserInfo(userInfo.data.userInfo)
                    userStore.setUserMenu(userInfo.data.userMenu)
                    userStore.setUserPerms(userInfo.data.userPerms)


                } else {
                    userStore.resetAuthInfo();
                    return "/login-form";
                }
            }
            if (!isInitRouter) {
                let menu = userStore.userMenu;
                let dynimcRoutes = generateRoutes(menu);
                dynimcRoutes.forEach((value, index) => {
                    console.log(value)
                    router.addRoute(value)
                })
                isInitRouter = true;
                return { ...to, replace: true }
            }
            return true;
        }
    } else {
        if (to.name !== "login-form") {
            return "/login-form";
        } else {
           return true;
        }
    }
});

function generateRoutes(menus) {
    const nodes = flattenTree({items : menus, path : ''});
    const routes = [];
    console.warn(modules)
    for (const node of nodes.filter(n => n.path !== undefined && n.path !== null && n.path !== '')) {
        const route = {
            path: node.path,
            name: node.code,
            component: () => defineAsyncComponent(modules[`/src/${node.component}.vue`]), // Replace with your NodeView component
            meta: { title : node.text, icon: node.icon , requiresAuth: true, layout: defaultLayout}
        };
        routes.push(route);
    }
    return routes;
}

function flattenTree(node, result = []) {
    result.push(node);
    if (node.items && node.items.length > 0) {
        for (const childNode of node.items) {
            flattenTree(childNode, result);
        }
    }
    return result;
}

export default router;
