import { createApp } from 'vue'
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import App from './App.vue'
import router from "@/router/index.js";
import store from "@/store";
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';

config({ licenseKey });
const app = createApp(App)
app.use(store);
app.use(router)
import { useUserStore} from "@/store/modules/user.js";
const userStore = useUserStore();

function hasPerms(perms) {
    console.log(perms)
    console.log(typeof perms)
    if (typeof perms === 'string') {
        console.log(userStore.userPerms.findIndex(d => d.perms === perms) > -1)
        return userStore.userPerms.findIndex(d => d.perms === perms) > -1
    }
    if (Array.isArray(perms)) {
        perms.forEach(d => {
            if (userStore.userPerms.findIndex(p => p.perms === d) > -1) {
                return true
            }
        })
    }
}
app.config.globalProperties.$hasPerms = hasPerms;

app.mount('#app')
